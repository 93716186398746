<template>
    <ideo-filter :name="name" :label="label" :display="filterDisplay" @clear="resetFilter">
        <div class="date-range d-flex flex-column flex-md-row gap-2">
            <ideo-form-group :label="$t('[[[Od]]]')" :nospace="true">
                <ideo-datetime :name="name" v-model="dateFromValue" type="datetime" />
            </ideo-form-group>
            <ideo-form-group :label="$t('[[[Do]]]')" :nospace="true">
                <ideo-datetime :name="name" v-model="dateToValue" type="datetime" />
            </ideo-form-group>
        </div>
    </ideo-filter>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useLocalization } from '@/plugins/localization';
import { formatDateTime } from '@/helpers/Utils';
import { DateTime } from 'luxon';

interface Props
{
    dateFrom: DateTime
    dateTo: DateTime
    label?: string
    name: string
}

const props = defineProps({
  "dateFrom": { default: null },
  "dateTo": { default: null },
  "label": { default: '' },
  "name": { default: '' }
});

defineOptions({
    name: 'date-range-filter',
    inheritAttrs: false,
});

const { $t } = useLocalization();

const emit = defineEmits(["update:dateFrom", "update:dateTo"]);


const dateFromValue = computed({
    get()
    {
        return props.dateFrom;
    },
    set(value: DateTime)
    {
        emit('update:dateFrom', value);
    }
});

const dateToValue = computed({
    get()
    {
        return props.dateTo;
    },
    set(value: DateTime)
    {
        emit('update:dateTo', value);
    }
});

const filterDisplay = computed<string>(() =>
{
    const from = dateFromValue.value ? $t('[[[od {0}|||%0]]]', formatDateTime(dateFromValue.value, 'yyyy-MM-dd HH:mm:ss')) : '';
    const to = dateToValue.value ? $t('[[[ do {0}|||%0]]]', formatDateTime(dateToValue.value, 'yyyy-MM-dd HH:mm:ss')) : '';

    return `${from}${to}`;
});

const resetFilter = (): void =>
{
    dateFromValue.value = null;
    dateToValue.value = null;
};
</script>
