/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import EmptyLayout from '@/views/layouts/EmptyLayout.vue';
import Dashboard from '@/modules/core/home/views/Dashboard.vue';
import Filemanager from '@/modules/core/files/views/MainView.vue';

export default [
    {
        path: 'dashboard',
        name: 'dashboard',
        meta: { auth: true },
        component: Dashboard
    },
    {
        // Konto użytkownika
        path: 'account',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: 'profile',
                name: 'core-account-profile',
                meta: { auth: true },
                component: () => import('@/modules/core/account/views/Profile.vue')
            },
            {
                path: 'password',
                name: 'core-account-password',
                meta: { auth: true, stop: true },
                component: () => import('@/modules/core/account/views/Password.vue')
            },
            {
                path: 'notifications',
                name: 'core-account-notifications',
                meta: { auth: true },
                component: () => import('@/modules/core/account/views/Notifications.vue')
            },
            {
                path: 'security',
                name: 'core-account-security',
                meta: { auth: true },
                component: () => import('@/modules/core/account/views/Security.vue')
            },
            {
                path: 'pats',
                name: 'core-pats',
                meta: { auth: true },
                component: () => import('@/modules/core/pat/views/PatView.vue')
            }
        ]
    },
    {
        path: 'admin',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            // Użytkownicy
            {
                path: 'users/add',
                name: 'core-users-add',
                meta: { auth: true },
                component: () => import('@/modules/core/users/views/UserForm.vue')
            },
            {
                path: 'users/:id(\\d+)',
                name: 'core-users-edit',
                meta: { auth: true, model: 'Ideo.Core.Data.Model.CoreUser' },
                component: () => import('@/modules/core/users/views/UserForm.vue')
            },
            {
                path: 'users/:filterName(\\w+)?/:filterValue(\\d+)?',
                name: 'core-users',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/users/views/UsersList.vue')
            },
            // Role
            {
                path: 'roles',
                name: 'core-roles',
                meta: { auth: true },
                component: () => import('@/modules/core/roles/views/RolesList.vue')
            },
            {
                path: 'roles/add',
                name: 'core-roles-add',
                meta: { auth: true },
                component: () => import('@/modules/core/roles/views/RoleForm.vue')
            },
            {
                path: 'roles/:id(\\d+)',
                name: 'core-roles-edit',
                meta: { auth: true, model: 'Ideo.Core.Data.Model.CoreRole' },
                component: () => import('@/modules/core/roles/views/RoleForm.vue')
            },
            // Zespoły
            {
                path: 'teams',
                name: 'core-teams',
                meta: { auth: true },
                component: () => import('@/modules/core/teams/views/TeamsList.vue')
            },
            {
                path: 'teams/add',
                name: 'core-teams-add',
                meta: { auth: true },
                component: () => import('@/modules/core/teams/views/TeamForm.vue')
            },
            {
                path: 'teams/:id(\\d+)',
                name: 'core-teams-edit',
                meta: { auth: true, model: 'Ideo.Core.Data.Model.CoreTeam' },
                component: () => import('@/modules/core/teams/views/TeamForm.vue')
            },
            // Organizacja
            {
                path: 'units',
                name: 'core-units',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/organization/views/UnitForm.vue')
            },
            {
                path: 'units/add',
                name: 'core-units-add',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/organization/views/UnitForm.vue')
            },
            {
                path: 'units/:id(\\d+)',
                name: 'core-units-edit',
                meta: { auth: true, model: 'Ideo.Core.Data.Model.CoreOrganizationalUnit' },
                props: true,
                component: () => import('@/modules/core/organization/views/UnitForm.vue')
            },
            // Zastępstwa
            {
                path: 'substitutions/add',
                name: 'core-substitutions-add',
                meta: { auth: true },
                component: () => import('@/modules/core/substitutions/views/SubstitutionForm.vue')
            },
            {
                path: 'substitutions/:id(\\d+)',
                name: 'core-substitutions-edit',
                meta: { auth: true },
                component: () => import('@/modules/core/substitutions/views/SubstitutionForm.vue')
            },
            {
                path: 'substitutions/:filterName(\\w+)?/:filterValue(\\d+)?',
                name: 'core-substitutions',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/substitutions/views/SubstitutionList.vue')
            }
        ]
    },
    {
        path: 'settings',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            // Konta
            {
                path: 'accounts',
                name: 'core-accounts',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/Accounts.vue')
            },
            // Bezpieczeństwo
            {
                path: 'security',
                name: 'core-security',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/Security.vue')
            },
            // Recaptcha
            {
                path: 'recaptcha',
                name: 'core-recaptcha',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/ReCaptcha.vue')
            },
            // Poczta
            {
                path: 'mailing',
                name: 'core-mailing',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/Mailing.vue')
            },
            // Sms
            {
                path: 'sms',
                name: 'core-sms',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/Sms.vue')
            },
            // Ustawienia sesji
            {
                path: 'session',
                name: 'core-session-settings',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/Sessions.vue')
            },
            // Paleta kolorów
            {
                path: 'colors',
                name: 'core-colors',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/Colors.vue')
            },
            // Sztuczna inteligencja
            {
                path: 'ai',
                name: 'core-ai',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/Ai.vue')
            },
            // Tłumaczenia
            {
                path: 'translations',
                name: 'core-translations',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/Translations.vue')
            },
            // Ldap
            {
                path: 'ldap',
                name: 'core-ldap',
                meta: { auth: true },
                component: () => import('@/modules/core/ldap/views/LdapSourcesList.vue'),
            },
            {
                path: 'add',
                props: true,
                name: 'core-ldap-add',
                meta: { auth: true },
                component: () => import('@/modules/core/ldap/views/LdapForm.vue')
            },
            {
                path: 'edit/:id',
                props: true,
                name: 'core-ldap-edit',
                meta: { auth: true },
                component: () => import('@/modules/core/ldap/views/LdapForm.vue')
            },
            // Powiadomienia
            {
                path: 'notifications/templates',
                name: 'core-notifications-templates',
                meta: { auth: true },
                component: () => import('@/modules/core/notifications/views/NotificationTemplatesList.vue')
            },
            {
                path: 'notifications/templates/add',
                name: 'core-notifications-templates-add',
                meta: { auth: true },
                component: () => import('@/modules/core/notifications/views/NotificationTemplateForm.vue')
            },
            {
                path: 'notifications/templates/edit/:id',
                name: 'core-notifications-templates-edit',
                meta: { auth: true },
                component: () => import('@/modules/core/notifications/views/NotificationTemplateForm.vue')
            },
            // Monitoring
            {
                path: 'monitoring',
                name: 'core-monitoring',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/Monitoring.vue')
            },
            // Logs - cele/targets
            {
                path: 'logs/targets',
                name: 'core-logs-targets',
                meta: { auth: true },
                component: () => import('@/modules/core/logs/views/LogsTargetList.vue'),
            },
            {
                path: 'logs/targets/add',
                props: true,
                name: 'core-logs-targets-add',
                meta: { auth: true },
                component: () => import('@/modules/core/logs/views/LogsTargetForm.vue')
            },
            {
                path: 'logs/targets/edit/:id',
                props: true,
                name: 'core-logs-targets-edit',
                meta: { auth: true },
                component: () => import('@/modules/core/logs/views/LogsTargetForm.vue')
            },
            // Logs - role
            {
                path: 'logs/rules',
                name: 'core-logs-rules',
                meta: { auth: true },
                component: () => import('@/modules/core/logs/views/LogsRuleList.vue'),
            },
            {
                path: 'logs/rules/add',
                props: true,
                name: 'core-logs-rules-add',
                meta: { auth: true },
                component: () => import('@/modules/core/logs/views/LogsRuleForm.vue')
            },
            {
                path: 'logs/rules/edit/:id',
                props: true,
                name: 'core-logs-rules-edit',
                meta: { auth: true },
                component: () => import('@/modules/core/logs/views/LogsRuleForm.vue')
            },
        ]
    },
    {
        path: 'tools',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            // Wyszukiwarka
            {
                path: 'search/indices',
                name: 'search-indices',
                meta: { auth: true },
                component: () => import('@/modules/core/search/views/IndicesList.vue')
            },
            {
                path: 'search/stopwords',
                name: 'search-stopwords',
                meta: { auth: true },
                component: () => import('@/modules/core/search/views/StopWordsList.vue')
            },
            {
                path: 'search/synonyms',
                name: 'search-synonyms',
                meta: { auth: true },
                component: () => import('@/modules/core/search/views/SynonymsList.vue')
            },
            // Historia logowania
            {
                path: 'monitoring/authlog',
                name: 'core-authlog',
                meta: { auth: true },
                component: () => import('@/modules/core/authlog/views/AuthlogList.vue')
            },
            // Rejestr zmian
            {
                path: 'monitoring/changelog/:sessionId(\\d+)?',
                name: 'core-changelog',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/changelog/views/ChangesList.vue')
            },
            {
                path: 'monitoring/changelog/diff/:id(\\d+)?',
                name: 'core-changelog-diff',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/changelog/views/ChangesView.vue')
            },
            // Historia zapytań AI
            {
                path: 'monitoring/aihistory',
                name: 'core-ai-history',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/ai/history/views/HistoryList.vue')
            },
            {
                path: 'monitoring/aihistory/:id',
                name: 'core-ai-history-details',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/ai/history/views/HistoryView.vue')
            },
            // Asystent
            {
                path: 'ai/assistant',
                name: 'core-ai-assistant-add',
                meta: { auth: true },
                component: () => import('@/modules/core/ai/assistant/views/AssistantView.vue')
            },
            {
                path: 'ai/assistant/:id',
                name: 'core-ai-assistant-edit',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/ai/assistant/views/AssistantView.vue')
            },
            // Pliki asystenta
            {
                path: 'ai/assistant/file',
                name: 'core-ai-assistant-file-add',
                meta: { auth: true },
                component: () => import('@/modules/core/ai/assistant/views/AssistantFileView.vue')
            },
            {
                path: 'ai/assistant/file/:id',
                name: 'core-ai-assistant-file-edit',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/ai/assistant/views/AssistantFileView.vue')
            },
            // Funkcje asystenta
            {
                path: 'ai/assistant/functions',
                name: 'core-ai-assistant-function',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/ai/assistant/views/AssistantFunctionList.vue')
            },
            {
                path: 'ai/assistant/function',
                name: 'core-ai-assistant-function-add',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/ai/assistant/views/AssistantFunctionView.vue')
            },
            {
                path: 'ai/assistant/function/:id',
                name: 'core-ai-assistant-function-edit',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/ai/assistant/views/AssistantFunctionView.vue')
            },
            // Harmonogram zadań
            {
                path: 'monitoring/scheduler',
                name: 'core-scheduler',
                meta: { auth: true },
                component: () => import('@/modules/core/scheduler/views/JobsList.vue')
            },
            {
                path: 'monitoring/scheduler/history/:type',
                name: 'core-scheduler-history',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/scheduler/views/JobHistory.vue')
            },
            {
                path: 'monitoring/scheduler/details/:type/:id',
                name: 'core-scheduler-details',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/scheduler/views/JobDetails.vue')
            },
            // Monitor kolejki zadań
            {
                path: 'monitoring/queues',
                name: 'core-queues',
                meta: { auth: true },
                component: () => import('@/modules/core/queues/views/MessageHistory.vue')
            },
            {
                path: 'monitoring/queues/details/:type/:id',
                name: 'core-queues-details',
                meta: { auth: true },
                component: () => import('@/modules/core/queues/views/MessageDetails.vue')
            }
        ]
    },
    {
        path: 'clients',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: 'list',
                name: 'clients',
                meta: { auth: true },
                component: () => import('@/modules/custom/clients/views/ClientsList.vue')
            },
            {
                path: ':publicId',
                name: 'clients-details',
                meta: { auth: true },
                component: () => import('@/modules/custom/clients/views/ClientDetails.vue')
            },
            {
                path: 'add',
                name: 'clients-add',
                meta: { auth: true },
                component: () => import('@/modules/custom/clients/views/ClientsAdd.vue')
            }
        ]
    },
    {
        path: 'installers',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: 'list',
                name: 'installers',
                meta: { auth: true },
                component: () => import('@/modules/custom/installers/views/InstallersList.vue')
            },
            {
                path: ':publicId',
                name: 'installers-edit',
                meta: { auth: true },
                component: () => import('@/modules/custom/installers/views/InstallersForm.vue')
            },
            {
                path: 'add',
                name: 'installers-add',
                meta: { auth: true },
                component: () => import('@/modules/custom/installers/views/InstallersForm.vue')
            }
        ]
    },
    {
        path: 'managers',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: 'list',
                name: 'managers',
                meta: { auth: true },
                component: () => import('@/modules/custom/managers/views/ManagersList.vue')
            },
            {
                path: ':publicId',
                name: 'managers-edit',
                meta: { auth: true },
                component: () => import('@/modules/custom/managers/views/ManagersForm.vue')
            },
            {
                path: 'add',
                name: 'managers-add',
                meta: { auth: true },
                component: () => import('@/modules/custom/managers/views/ManagersForm.vue')
            }
        ]
    },
    {
        path: 'applicationsVersions',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: 'list',
                name: 'application-versions',
                meta: { auth: true },
                component: () => import('@/modules/custom/applicationsVersions/views/ApplicationsVersionsList.vue')
            },
            {
                path: ':publicId',
                name: 'application-versions-edit',
                meta: { auth: true },
                component: () => import('@/modules/custom/applicationsVersions/views/ApplicationsVersionsForm.vue')
            },
            {
                path: 'add',
                name: 'application-versions-add',
                meta: { auth: true },
                component: () => import('@/modules/custom/applicationsVersions/views/ApplicationsVersionsForm.vue')
            }
        ]
    },
    {
        path: 'applicationsPlugins',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: 'list',
                name: 'application-plugins',
                meta: { auth: true },
                component: () => import('@/modules/custom/applicationsPlugins/views/ApplicationsPluginsList.vue')
            },
            {
                path: ':publicId',
                name: 'application-plugins-edit',
                meta: { auth: true },
                component: () => import('@/modules/custom/applicationsPlugins/views/ApplicationsPluginsForm.vue')
            },
            {
                path: 'add',
                name: 'application-plugins-add',
                meta: { auth: true },
                component: () => import('@/modules/custom/applicationsPlugins/views/ApplicationsPluginsForm.vue')
            },
            {
                path: ':pluginPublicId/versions/list',
                name: 'application-plugins-versions',
                meta: { auth: true },
                component: () => import('@/modules/custom/applicationsPluginsVersions/views/ApplicationsPluginsVersionsList.vue')
            },
            {
                path: ':pluginPublicId/versions/:publicId',
                name: 'application-plugins-versions-edit',
                meta: { auth: true },
                component: () => import('@/modules/custom/applicationsPluginsVersions/views/ApplicationsPluginsVersionsForm.vue')
            },
            {
                path: ':pluginPublicId/versions/add',
                name: 'application-plugins-versions-add',
                meta: { auth: true },
                component: () => import('@/modules/custom/applicationsPluginsVersions/views/ApplicationsPluginsVersionsForm.vue')
            }
        ]
    },
    {
        path: 'filemanager',
        name: 'filemanager',
        meta: { auth: true },
        component: Filemanager
    },
    {
        path: 'trash',
        name: 'trash',
        meta: { auth: true },
        component: () => import('@/modules/core/trash/views/TrashList.vue')
    }
];
